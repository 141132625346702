<template>
  <v-card
    id="stock-movement"
    class="px-3 py-5">
    <v-row>
      <v-col>
        <h6 class="text-h6">
          Stock Movement (ประวัติการเคลื่อนไหวของ Stock)
        </h6>
      </v-col>
    </v-row>
    <v-row v-if="ready">
      <v-col>
        <ProductStockMovementBox
          :product-id="product.id"
          :product-name="product.name"
          :model="product.model"
          :brand="product.brand"
          :variant="product.variant"
          :skus="product.skus"
          :categories="product.categories"
          :tags="product.tags"
          :photo-urls="product.photoUrls"
          :warehouse-id="warehouseSelected.id"
          :selected-sku-id="skuId"
          :warehouse-name="warehouseSelected.name"
          @change-sku="changeSku" />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="auto">
        <WarehouseSelect
          v-model="warehouseSelected"
          :items="mapWarehouse"
          item-text="name"
          item-value="id"
          :menu-props="{ offsetY: true }"
          label="Warehouses (คลัง)"
          return-object
          outlined />
        <!-- <v-select
          v-model="warehouseSelected"
          :items="mapWarehouse"
          :menu-props="{ offsetY: true }"
          item-value="name"
          item-text="name"
          label="Warehouses (คลัง)"
          class="select-warehouse"
          return-object
          auto-select-first
          outlined
          hide-details
          dense
          @input="changeWarehouse" /> -->
      </v-col>
      <v-col cols="auto">
        <v-select
          v-model="movementOperation"
          :items="movementOperationItems"
          :menu-props="{ offsetY: true }"
          class="movement-operation"
          auto-select-first
          label="Display (การแสดงผล)"
          hide-details
          outlined
          dense />
      </v-col>

      <v-col
        v-if="enableDateRange"
        cols="auto">
        <v-menu
          ref="menu"
          v-model="menu"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
          min-width="290px">
          <template v-slot:activator="{ on, attrs }">
            <v-row>
              <v-col cols="auto">
                <v-text-field
                  v-model="dateRangeText[0]"
                  label="Start Date"
                  prepend-icon="mdi-calendar"
                  class="date-range-input"
                  max-width="290px"
                  readonly
                  hide-details
                  outlined
                  dense
                  v-bind="attrs"
                  v-on="on" />
              </v-col>
              <v-col cols="auto">
                <v-text-field
                  v-model="dateRangeText[1]"
                  label="End Date"
                  class="date-range-input"
                  max-width="290px"
                  readonly
                  hide-details
                  outlined
                  dense
                  v-bind="attrs"
                  v-on="on" />
              </v-col>
            </v-row>
          </template>
          <v-date-picker
            v-model="dates[0]"
            :max="$dayjs(new Date()).format('YYYY-MM-DD')" />
          <v-date-picker
            v-model="dates[1]"
            :max="$dayjs(new Date()).format('YYYY-MM-DD')" />
        </v-menu>
      </v-col>
      <v-spacer />
      <v-col cols="auto">
        <v-btn
          color="secondary"
          @click="exportDataToCSV()">
          Export
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <stock-movement-table
          :height="550"
          :warehouse-id="warehouseId"
          :sku-id="skuId"
          :start-date="startDate"
          :end-date="endDate"
          :movement-operation="movementOperation"
          pagination />
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { ExportToCsv } from 'export-to-csv'
import InventoryProvider from '@/resources/InventoryProvider'
import StockMovementTable from '@/views/inventory/components/StockMovementTable.vue'
import ProductProvider from '@/resources/ProductProvider'
import WarehouseSelect from '@/components/WarehouseSelect.vue'
import ProductStockMovementBox from '../components/ProductStockMovementBox.vue'

const InventoryService = new InventoryProvider()

const ProductService = new ProductProvider()
export default {
  components: {
    stockMovementTable: StockMovementTable,
    ProductStockMovementBox,
    WarehouseSelect
  },
  data () {
    return {
      movementOperationItems: [
        'All',
        'Period'
      ],
      movementOperation: 'All',
      dates: [
        this.$dayjs().subtract(7, 'd').format('YYYY-MM-DD'),
        this.$dayjs().format('YYYY-MM-DD')
      ],
      warehouseSelected: {},
      menu: false,
      product: {},
      ready: false
    }
  },
  computed: {
    ...mapGetters({
      mapWarehouse: 'Store/mapWarehouse',
      storeSetting: 'Store/storeSetting'
    }),
    enableDateRange () {
      return this.movementOperation === 'Period'
    },
    dateRangeText () {
      return this.sortDate()
    },
    skuId () {
      return this.$route.params.skuId ? this.$route.params.skuId : ''
    },
    productId () {
      return this.$route.query?.['product-id'] || null
    },
    warehouseId () {
      return this.$route.query.warehouseId ? parseInt(this.$route.query.warehouseId) : 0
    },
    startDate () {
      return this.dates[0] ? parseInt(this.$dayjs(this.dates[0], 'YYYY-MM-DD').format('x')) : null
    },
    endDate () {
      return this.dates[1] ? parseInt(this.$dayjs(this.dates[1], 'YYYY-MM-DD').format('x')) : null
    }
  },
  watch: {
    warehouseSelected () {
      this.changeWarehouse()
    }
  },
  created () {
    this.warehouseSelected = this.mapWarehouse.find((r) => +r.id === +this.warehouseId)
    this.getProduct()
  },
  methods: {
    ...mapActions({
      setSnackbar: 'Components/setSnackbar',
      setLoading: 'Components/setLoading'
    }),
    async getProduct () {
      try {
        const { data } = await ProductService.getProductById(this.productId)
        this.product = data
      } catch (error) {
        this.setSnackbar({
          value: true,
          message: `[GET-PRODUCT]: ${error.message}`,
          type: 'error'
        })
      } finally {
        this.ready = true
      }
    },
    sortDate () {
      const dates = []
      this.dates = this.dates.sort((a, b) => (this.$dayjs(a, 'YYYY-MM-DD').format('x') - this.$dayjs(b, 'YYYY-MM-DD').format('x')))
      for (let i = 0; i < this.dates.length; i++) {
        dates.push(this.$dayjs(this.dates[i], 'YYYY-MM-DD').format('DD MMM YYYY'))
      }
      return dates
    },
    changeSku (val) {
      this.$router.push({
        name: 'StockMovement',
        params: { skuId: val.id },
        query: { 'warehouseId': this.warehouseId, 'product-id': this.productId }
      })
    },
    changeWarehouse () {
      this.$router.push({
        name: 'StockMovement',
        params: { skuId: this.skuId },
        query: { 'warehouseId': this.warehouseSelected.id, 'product-id': this.productId }
      })
    },
    mapReferenceValue (reference) {
      if (reference.documentNo) {
        return reference.documentNo
      }
      switch (reference.type) {
        case 'withdrawnote':
          return reference.value ? `WN${ reference.value.toString().padStart(6, '0') }` : ''
        case 'deliverynote':
          return reference.value ? `DN${ reference.value.toString().padStart(6, '0') }` : ''
        default:
          return reference.value
      }
    },
    async exportDataToCSV () {
      try {
        this.setLoading({ active: true })

        const { data } = await InventoryService.getStockMovement({
          page: 1,
          limit: 1000,
          skuId: this.skuId,
          warehouse: this.warehouseId
        })

        const rounds = new Array(data.pages).fill(0).map((item, index) => index)

        for await (const round of rounds) {
          const { data: reportsData } = await InventoryService.getStockMovement({
            page: round + 1,
            limit: 1000,
            skuId: this.skuId,
            warehouse: this.warehouseId
          })

          const reports = reportsData.results
          const items = reports.map((report) => ({
              date: this.$dayjs(report.createdAt).format('DD MMM YYYY | HH:mm:ss'),
              warehouse: report.warehouse.code,
              type: report.reference.type,
              reference: this.mapReferenceValue(report.reference),
              lastUpdate: report.updatedBy.email,
              add: (report.amount > 0) ? report.amount : '-',
              deduct: (report.amount < 0) ? report.amount : '-',
              remain: report.onHandQty
            }))

          const options = {
            filename: `StockMovement-Report-${round + 1}`,
            showLabels: true,
            useKeysAsHeaders: true
          }

          const csvExporter = new ExportToCsv(options)
          csvExporter.generateCsv(items)
        }
      } catch (error) {
        console.error('exportStockMovement', error)
        this.setSnackbar({
          value: true,
          message: `[GET-REPORT-ERROR]: ${error.message}`,
          type: 'error'
        })
      } finally {
        this.setLoading({ active: false })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
#stock-movement {
  min-height: calc(100vh - 68px);
}
.movement-operation {
  width: 110px;
}
.date-range-input {
  width: 255px;
}
</style>
