<template>
  <v-simple-table
    ref="stock-table"
    fixed-header
    :loading="getting"
    style="font-size: 12px"
    :height="height">
    <thead>
      <tr>
        <th
          v-for="(head, index) in tableHead"
          :key="`stock-table-head-${index}`"
          :class="head.align"
          :width="head.width">
          {{ head.label }}
        </th>
      </tr>
    </thead>
    <tbody>
      <tr
        v-if="getting"
        class="simple-table-loading">
        <td :colspan="tableHead.length">
          <v-progress-linear
            color="primary"
            indeterminate />
        </td>
      </tr>
      <tr
        v-for="(item, index) in stockMovementData"
        :key="`stock-table-body-${index}`">
        <td>
          {{ $dayjs(item.createdAt).format('DD MMM YYYY | HH:mm:ss') }}
        </td>
        <td>
          {{ item.warehouse.code }}
        </td>
        <td>
          {{ mapReferenceType(item.reference.type) }}
        </td>
        <td>
          <router-link
            :to="mapReferenceLink(item.reference)"
            target="_blank">
            {{ mapReferenceValue(item.reference) }}
          </router-link>
        </td>
        <td>
          {{ item.updatedBy.email || '-' }}
        </td>
        <td
          class="text-center"
          :class="{'success--text': isIntegers(item.amount, 'positive')}">
          {{ checkIntegers(item.amount, 'positive') }}
        </td>
        <td
          class="text-center"
          :class="{'error--text': isIntegers(item.amount, 'negative')}">
          {{ checkIntegers(item.amount, 'negative') }}
        </td>
        <td class="text-center">
          {{ item.onHandQty | showNumberFormat() }}
        </td>
      </tr>
    </tbody>
  </v-simple-table>
</template>

<script>
import InventoryProvider from '@/resources/InventoryProvider'
import { mapActions } from 'vuex'

const InventoryService = new InventoryProvider()

export default {
  props: {
    height: {
      type: [Number, String],
      default: ''
    },
    warehouseId: {
      type: Number,
      default: 0
    },
    skuId: {
      type: String,
      required: true
    },
    startDate: {
      type: Number,
      default: null
    },
    endDate: {
      type: Number,
      default: null
    },
    movementOperation: {
      type: String,
      default: 'All'
    },
    pagination: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      tableHead: [
        {
          label: 'Date Time',
          align: 'text-left',
          width: 180
        },
        {
          label: 'Warehouse',
          align: 'text-left',
          width: 150
        },
        {
          label: 'Type',
          align: 'text-left',
          width: 150
        },
        {
          label: 'Ref.',
          align: 'text-left',
          width: 150
        },
        {
          label: 'Last Update By',
          align: 'text-left',
          width: 150
        },
        {
          label: 'Add',
          align: 'text-center',
          width: 80
        },
        {
          label: 'Deduct',
          align: 'text-center',
          width: 80
        },
        {
          label: 'Remain',
          align: 'text-center',
          width: 80
        }
      ],
      stockMovementData: [],
      getting: false,
      isLoadComplete: false,
      page: 0,
      perPage: 25,
      totalPage: 1
    }
  },
  computed: {
    startDateTime () {
      return this.startDate ? new Date(parseInt(this.$dayjs(this.startDate, 'DD MMM YYYY').format('x'))) : null
    },
    endDateTime () {
      return this.endDate ? new Date(parseInt(this.$dayjs(this.endDate, 'DD MMM YYYY').add(1, 'day').subtract(1, 'millisecond').format('x'))) : null
    }
  },
  watch: {
    warehouseId () {
      this.resetFetchStockMovement()
    },
    skuId () {
      this.resetFetchStockMovement()
    },
    startDate () {
      if (this.startDate && this.endDate) {
        this.resetFetchStockMovement()
      }
    },
    endDate () {
      if (this.startDate && this.endDate) {
        this.resetFetchStockMovement()
      }
    },
    movementOperation (val) {
      if (val === 'All') {
        this.resetFetchStockMovement()
      }
    }
  },
  created () {
    this.fetchStockMovement()
  },
  mounted () {
    this.$refs['stock-table'].$vnode.elm.firstElementChild.addEventListener('scroll', this.handleScroll)
  },
  beforeDestroy () {
    this.$refs['stock-table'].$vnode.elm.firstElementChild.addEventListener('scroll', this.handleScroll)
  },
  methods: {
    ...mapActions({
      setErrorPage: 'Components/setErrorPage'
    }),
    mapReferenceType (type) {
      switch (type) {
        case 'billstockdeduct':
          return 'Bill stock deduct'
        case 'createproduct':
          return 'Create product'
        case 'developcheckstock':
          return 'Develop checkstock'
        case 'withdrawnote':
          return 'Withdraw note'
        case 'deliverynote':
          return 'Delivery note'
        case 'migration':
          return 'Migration'
        case 'manualadjust':
          return 'Manualadjust'
        case 'credit_note':
          return 'Credit note'
        default:
          return 'Error !!'
      }
    },
    handleScroll (event) {
      const e = event.target
      const scrollY = e.scrollHeight - e.clientHeight - 100
      if (e.scrollTop >= scrollY && !this.getting) {
        this.fetchStockMovement()
      }
    },
    mapReferenceValue (reference) {
      if (reference.documentNo) {
        return reference.documentNo
      }
      switch (reference.type) {
        case 'withdrawnote':
          return reference.value ? `WN${ reference.value.toString().padStart(6, '0') }` : ''
        case 'deliverynote':
          return reference.value ? `DN${ reference.value.toString().padStart(6, '0') }` : ''
        default:
          return reference.value
      }
    },
    mapReferenceLink (reference) {
      switch (reference.type) {
        case 'withdrawnote':
          return {
            name: 'WithdrawNote',
            query: {
              id: reference.value
            }
          }
        case 'deliverynote':
          return {
            name: 'DeliveryNote',
            query: {
              id: reference.value
            }
          }
        case 'billstockdeduct':
          return `/order/all?channel=all&id=${reference.value}&is_main=false`
        case 'credit_note':
          return `/credit-note/${reference.value}`
        case 'bill_reserve':
          return `/order/all?channel=all&id=${reference.value}&is_main=false`
        default:
          return {
            name: 'Home'
          }
      }
    },
    isIntegers (number, integerType) {
      const data = this.checkIntegers(number, integerType)
      return data && data !== '-'
    },
    checkIntegers (number, integerType) {
      if (integerType === 'positive' && number > 0) {
        return `+${ parseFloat(number).toFixed(0).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') }`
      }
      if (integerType === 'negative' && number < 0) {
        return parseFloat(number).toFixed(0).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
      }
      return '-'
    },
    resetFetchStockMovement () {
      this.getting = false
      this.page = 0
      this.totalPage = 1
      this.isLoadComplete = false
      this.stockMovementData = []
      this.fetchStockMovement()
    },
    async fetchStockMovement () {
      this.getting = true
      if (this.page < this.totalPage) {
        this.page++
        try {
          const { data } = await InventoryService.getStockMovement({
            page: this.page,
            limit: this.perPage,
            skuId: this.skuId,
            warehouse: this.warehouseId,
            startDate: (this.movementOperation === 'Period') ? this.startDateTime : null,
            endDate: (this.movementOperation === 'Period') ? this.endDateTime : null
          })
          this.totalPage = data.pages
          this.stockMovementData.push(...data.results)
        } catch (error) {
          console.error('fetchStockMovement', error)
          this.setErrorPage(error.code)
        }
      }
      this.isLoadComplete = this.page >= this.totalPage
      this.getting = false
    }
  }
}
</script>

<style scoped>
.v-data-table > .v-data-table__wrapper > table > tbody > tr.simple-table-loading td {
  padding: 0;
  height: 4px;
  border: 0 !important;
}
</style>
